import { AxiosError } from "axios";
import HttpClient from "../clients/HttpClient";
import { CreateServiceReportRequest, ServiceReport, ServiceReportObject } from "../models/ServiceReportModels";
import AuthService from "./authService";

export async function GetServiceReportObjects(): Promise<{ serviceReportObjects: ServiceReportObject[], hasError: boolean }> {
    try {
        var session = AuthService.getSession()

        if (session?.access_token) {
            var client = HttpClient(session?.access_token);

            var serviceReportObjects = await client.get(`/servicereport`).then((response) => {
                if (response.data && response.data.length > 0) {
                    return { serviceReportObjects: response.data, hasError: false };
                }
                else {
                    return { serviceReportObjects: [] as ServiceReportObject[], hasError: false };
                }
            })

            return serviceReportObjects;
        }

        return { serviceReportObjects: [] as ServiceReportObject[], hasError: true };
    }
    catch (error) {
        var axiosError = error as AxiosError;

        if (axiosError.response?.status == 401) {
            window.location.replace("/login/authentication/error");
        }

        return { serviceReportObjects: [] as ServiceReportObject[], hasError: true };
    }
}

export async function GetServiceReportObject(id: string): Promise<{ serviceReportObject: ServiceReportObject, hasError: boolean }> {
    try {
        var session = AuthService.getSession()

        if (session?.access_token) {
            var client = HttpClient(session?.access_token);

            var serviceReportObject = await client.get(`/servicereport/${id}`).then((response) => {
                if (response.data) {
                    return { serviceReportObject: response.data, hasError: false };
                }
                else {
                    return { serviceReportObject: {} as ServiceReportObject, hasError: false };
                }
            })

            return serviceReportObject;
        }

        return { serviceReportObject: {} as ServiceReportObject, hasError: true };
    }
    catch (error) {
        var axiosError = error as AxiosError;

        if (axiosError.response?.status == 401) {
            window.location.replace("/login/authentication/error");
        }

        return { serviceReportObject: {} as ServiceReportObject, hasError: true };
    }
}

//"6244625"
export async function CreateServiceReportObject(request: CreateServiceReportRequest): Promise<{ errorMessage: string, hasError: boolean, serviceReportId: string }>
{
    try {
        var session = AuthService.getSession();

        if (session?.access_token) {
            var client = HttpClient(session?.access_token);

            var result = await client.post(`/servicereport`, request).then((response) => {
                return { hasError: false, errorMessage: '', serviceReportId: response.data.serviceReportId }
            });

            return result;
        }

        return { hasError: true, errorMessage: "Gick inte att skicka serviceanmälan, testa gärna igen!", serviceReportId:'' }
    }
    catch (error) {
        var axiosError = error as AxiosError;

        if (axiosError.response?.status == 401) {
            AuthService.clearSession();
        }

        return { hasError: true, errorMessage: "Gick inte att skicka serviceanmälan, testa gärna igen!", serviceReportId: '' }
    }
}
