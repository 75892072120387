import React, { ChangeEvent, useEffect, useState } from 'react';
import { Contract } from '../models/ContractModels';
import { Customer } from '../models/CustomerModels';
import { GetContracts } from '../services/ContractService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CreateServiceReportObject, GetServiceReportObject } from '../services/ServiceReportService';
import { CreateServiceReportRequest, ServiceReport } from '../models/ServiceReportModels';
import moment from 'moment';

interface DisplaySettings {
    showLoadingSpinner: boolean
    showLoadingSpinnerContracts: boolean
    showErrorContracts: boolean
    showErrorInformation: boolean
    showResetContactInformation: boolean
    showLoadingSpinnerCreateServiceReport: boolean
    showErrorCreateServiceReport: boolean;
    showCreatedServiceReportResponse: boolean;
}

interface ContactInformation {
    name: string,
    phone: string,
    email: string,
}

interface Image {
    url: string,
    bytes: File,
}

const CustomerCreateServiceReportComponent = ({ customer, onCreate }: { customer: Customer, onCreate: any }) => {

    const [contracts, setContracts] = useState<Contract[]>([] as Contract[]);
    const [contactInformation, setContactInformation] = useState<ContactInformation>({ name: '', phone: '', email: '' });
    const [gettingContracts, setGettingContracts] = useState<boolean>(false);

    const [description, setDescription] = useState<string>('');
    const [selectedContract, setSelectedContract] = useState<string | undefined>(undefined);

    const [lastCreatedServiceReport, setLastCreatedServiceReport] = useState<ServiceReport>();

    const [images, setImages] = useState([] as Image[]);

    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({ showResetContactInformation: false } as DisplaySettings);

    useEffect(() => {
        if (!gettingContracts) {
            setGettingContracts(true);
            getContracts();
        }

        if (customer.identificationNr !== undefined && customer.identificationNr !== '') {
            setContactInformation({
                name: customer.name,
                phone: customer.phoneMobile,
                email: customer.email,
            });
        }
    }, [customer]);

    function onImageChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            var url = URL.createObjectURL(event.target.files[0]);
            var file = event.target.files[0];

            setImages((prevState) => [...images, { url: url, bytes: file }]);
        }
    }

    function handleNameChangeEvent(event: ChangeEvent<HTMLInputElement>) {
        setContactInformation((prevState) => ({ ...prevState, name: event.target.value }));
    }

    function handlePhoneChangeEvent(event: ChangeEvent<HTMLInputElement>) {
        setContactInformation((prevState) => ({ ...prevState, phone: event.target.value }));
    }

    function handleEmailChangeEvent(event: ChangeEvent<HTMLInputElement>) {
        setContactInformation((prevState) => ({ ...prevState, email: event.target.value }));
    }

    function handleSelectedContractEvent(event: ChangeEvent<HTMLSelectElement>) {
        setSelectedContract(event?.target.value);
    }

    const handleCreateServiceReport = async () =>  {
        setDisplaySettings((prevState) => ({ ...prevState, showLoadingSpinnerCreateServiceReport: true, showErrorCreateServiceReport: false }));

        var requestModel = {
            contactInfoReporter:
            {
                name: contactInformation.name,
                email: contactInformation.email,
                phone: contactInformation.phone,
            },
            issue:
            {
                concerns: 'Apartment.All',
                description: description,
                type: 'Other'
            },
            mediaBytes: await Promise.all(images.map(x => fileToBase64(x.bytes))),
            objectNr: selectedContract
        } as CreateServiceReportRequest;

        CreateServiceReportObject(requestModel).then((response) => {
            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showErrorCreateServiceReport: true }));
            }
            else {
                GetServiceReportObject(response.serviceReportId).then((serviceReportResponse) => {
                    setDisplaySettings((prevState) => ({ ...prevState, showCreatedServiceReportResponse: true }));
                    setLastCreatedServiceReport(serviceReportResponse.serviceReportObject.serviceReports[0])
                    onCreate(serviceReportResponse.serviceReportObject);
                    setDescription('');
                    resetContactInformation();
                    removeImages();
                }).finally(() => {
                    setDisplaySettings((prevState) => ({ ...prevState, showLoadingSpinnerCreateServiceReport: false }));
                });
            }
        }).finally(() => {
            
        });
    }

    function resetContactInformation() {
        setContactInformation({
            name: customer.name,
            phone: customer.phoneMobile,
            email: customer.email,
        });
    }

    function showContactInformationModified() {
        if (customer && contactInformation) {
            return customer.name === contactInformation.name && customer.email === contactInformation.email && customer.phoneMobile === contactInformation.phone;
        }
        return false;
    }

    function isFormsDisabled() {
        if (displaySettings.showLoadingSpinnerCreateServiceReport) {
            return true;
        }

        if (contracts.length == 0) {
            return true;
        }

        return false;
    }

    function isCreateServiceDisabled() {
        if (description.length < 10) {
            return true;
        }

        if (contactInformation?.name && contactInformation?.name.length <= 0) {
            return true;
        }

        if (contactInformation?.email && contactInformation?.email.length <= 0) {
            return true;
        }

        if (contactInformation?.phone && contactInformation?.phone.length <= 0) {
            return true;
        }

        return false;
    }

    const fileToBase64 = (file: File | Blob): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '')
                if (encoded && encoded.length % 4 > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4))
                }

                resolve(encoded as string);
            };

            reader.readAsDataURL(file);
            reader.onerror = reject;
        });

    const removeImage = (index: number) => {
        setImages(images.filter((_, i) => i !== index));
    }

    const removeImages = () => {
        setImages([] as Image[]);
    }

    const getContracts = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showLoadingSpinnerContracts: true, showErrorContracts: false }));
        setContracts([] as Contract[]);

        GetContracts().then((response) => {
            
            if (response && response.contracts && response.contracts.length > 0) {
                setContracts(response.contracts.filter(contract => contract.contractType === 'Contract.Commercial' || contract.contractType === 'Contract.Residential' || contract.contractType === 'Contract.Student'))
                setSelectedContract(response.contracts[0].rentalObject.nr);
            };

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showErrorContracts: true }));
            }
        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, showLoadingSpinnerContracts: false }));
        });
    }

    const PreviewImage = ({ imageSrc, index }: {imageSrc: string, index:number}) => {
        return (
            <div className="service-report-previewImage-container">
                <img className="service-report-previewImage" src={imageSrc} alt="Tillagd bild"></img>
                <span onClick={() => removeImage(index)} className="service-report-previewImage-remove"><FontAwesomeIcon icon="circle-xmark" /></span>
            </div>
        );
    }

    const handleClickSelectFile = () => {
        document.getElementById("selectImageUploadInput")?.click();
    }

    const handleClickCloseCreateServiceReportResponse = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showCreatedServiceReportResponse: false }));
    }

    const ShowRentObjectAsText = ({ contracts }: { contracts: Contract[] | undefined }) => {
        var contract = contracts && contracts?.length > 0 ? contracts[0] : null;

        return (<div className="service-report-create-objectName h5">{contract?.rentalObject.address.street} {contract?.rentalObject.address.postcode} {contract?.rentalObject.address.city} [{contract?.rentalObject.nr}]</div>);
    }

    const ShowRentObjectsAsSelect = ({ contracts }: { contracts: Contract[] }) => {
        return (<select className="form-select single-as-label" aria-label="Default select example" value={selectedContract} onChange={handleSelectedContractEvent} disabled={isFormsDisabled()}>
            {contracts?.map(contract => <option value={contract.rentalObject.nr} key={contract.contractNr}>{contract.rentalObject.address.street} {contract.rentalObject.address.postcode} {contract.rentalObject.address.city} [{contract.rentalObject.nr}]</option>)}
        </select>);
    }

    const ShowGetCustomer = () => {
        return (
            <div>
                <div className="spinner-border spinner-border-small me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                Hämtar kundinformation
            </div>
            )
    }

    const ShowCreateCustomReportResponse = () => {
        return (
            <div>
                <h5>Tack för din serviceanmälan med ärendenummer {lastCreatedServiceReport?.number}.</h5><br />
                <h6>Vi återkommer inom 24 timmar helgfri vardag.</h6><br />
                <h6>Med vänlig hälsning</h6>
                <h6>MKB Fastighets AB</h6>
                <button type="button" className="btn btn-sm btn-primary mt-5" onClick={handleClickCloseCreateServiceReportResponse}>Ny serviceanmälan</button>
            </div>
        )
    }

    function handleDescriptionChangeEvent(event: ChangeEvent<HTMLTextAreaElement>) {
        var pattern = /[\wåÅöÖäÄçÇ"!?,.\-_+=\s()#:;@£$%&/øæØÆØÆé¢*§]+/;
        setDescription((event.target.value.match(pattern) || []).pop() || '');
    }

    return (
        <div>
            <div className="component-header text-border">Ny serviceanmälan</div>
            {(() => {
                if (displaySettings.showCreatedServiceReportResponse) {
                    return (<ShowCreateCustomReportResponse />)
                }
                else if (customer.identificationNr !== undefined && customer.identificationNr !== '') {
                    return (<div>
                        <div className="row">
                            <label className="mt-2 large">Anmälan gäller:</label>
                            {(() => {
                                if (displaySettings.showLoadingSpinnerContracts) {
                                    return (<div><div className="spinner-border spinner-border-small" role="status"></div><span className="spinner-border-label">Hämtar hyresobjekt</span></div>)
                                }
                                else if (displaySettings.showErrorContracts) {
                                    return (<div>
                                        <span className="error-label">Gick inte att hämta hyresobjekten, försök gärna igen!</span>
                                        <button type="button" className="btn btn-sm btn-primary ms-5 mt-2" onClick={getContracts}>Hämta hyresobjekten igen</button>
                                    </div>)
                                }
                                else if (contracts.length > 0) {
                                    return (contracts && contracts?.length > 1 ? <ShowRentObjectsAsSelect contracts={contracts}></ShowRentObjectsAsSelect> : <ShowRentObjectAsText contracts={contracts}></ShowRentObjectAsText>)
                                }
                                else {
                                    return (<div><span className="error-label">Hitta inga hyresobjekt</span></div>)
                                }
                            })()}
                        </div>
                        <div className="row mb-2">
                            <label className="mt-2 large">Beskriv vad du vill ha hjälp med, t.ex. att kranen droppar eller fönster som inte går att stänga. Du kan även anmäla fel i gemensamma utrymmen.</label>
                            <textarea className="form-control" rows={4} aria-label="With textarea" placeholder='' onChange={handleDescriptionChangeEvent} value={description} disabled={isFormsDisabled()}></textarea>
                            {description.length > 0 && description.length < 10 ? <span className="text-danger pt-1">Beskriv ditt ärende med åtminstone 10 tecken</span> : null}
                        </div>
                        <div className="row mb-4">
                            <div className="col-md-8 flex flex-wrap">
                                {images.map((imageSrc, index) => <PreviewImage imageSrc={imageSrc.url} index={index} key={imageSrc.url}></PreviewImage>)}
                            </div>
                            <div>
                                <button type="button" className="btn btn-sm btn-outline-primary mt-2" onClick={handleClickSelectFile}>Lägg till en bild</button>
                                <input type="file" className="service-report-previewImage-fileInput" multiple accept="image/*" id="selectImageUploadInput" onChange={onImageChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="row">
                                    <label className="mt-2">Namn:</label>
                                    <input type="text" className="form-control" placeholder="Namn" value={contactInformation?.name ?? ''} onChange={handleNameChangeEvent} disabled={isFormsDisabled()} />
                                    {!contactInformation?.name || contactInformation?.name?.length <= 0 ? <span className="text-danger pt-1">Namn är obligatoriskt</span> : null}
                                </div>
                                <div className="row">
                                    <label className="mt-2">Telefon:</label>
                                    <input type="text" className="form-control" placeholder="Telefon" value={contactInformation?.phone ?? ''} onChange={handlePhoneChangeEvent} disabled={isFormsDisabled()} />
                                    {!contactInformation?.phone || contactInformation?.phone?.length <= 0 ? <span className="text-danger pt-1">Telefon är obligatoriskt</span> : null}
                                </div>
                                <div className="row">
                                    <label className="mt-2">E-post:</label>
                                    <input type="text" className="form-control" placeholder="Epost" value={contactInformation?.email ?? ''} onChange={handleEmailChangeEvent} disabled={isFormsDisabled()} />
                                    {!contactInformation?.email || contactInformation?.email?.length <= 0 ? <span className="text-danger pt-1">Epost är obligatoriskt</span> : null}
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-12 text-start mt-2">
                                <button type="button" className="btn btn-sm btn-outline-primary d-none" onClick={resetContactInformation} disabled={showContactInformationModified()}>Återställ kontakt uppgifter</button>

                                <button type="button" className="btn btn-sm btn-primary mt-2" onClick={handleCreateServiceReport} disabled={isFormsDisabled() || isCreateServiceDisabled()}>
                                    {displaySettings.showLoadingSpinnerCreateServiceReport ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : null}
                                    {displaySettings.showLoadingSpinnerCreateServiceReport ? "Skickar in" : "Skicka in"}
                                </button>
                            </div>
                        </div>
                        {displaySettings.showErrorCreateServiceReport ? <div className="row text-end"><span className="error-text">Något gick fel när ärendet skickades in, försök gärna igen. Ifall det fortsatt inte fungerar, kontakta vår support.</span></div> : null}
                    </div>)
                }
                else {
                    return (<ShowGetCustomer />)
                }
            })()}
        </div>
    );
}

export default CustomerCreateServiceReportComponent;