import { AxiosError } from "axios";
import HttpClient from "../clients/HttpClient";
import { Contract } from "../models/ContractModels";
import AuthService from "./authService";

export async function GetContracts(): Promise<{ contracts: Contract[], hasError: boolean }> {
    try {
        var session = AuthService.getSession()

        if (session?.access_token) {
            var client = HttpClient(session?.access_token);

            var contracts = await client.get(`/contract`).then((response) => {
                if (response.data && response.data.length > 0) {
                    return { contracts: response.data, hasError: false };
                }
                else {
                    return { contracts: [] as Contract[], hasError: false };
                }
            })

            return contracts;
        }

        return { contracts: [] as Contract[], hasError: true };
    }
    catch (error) {
        var axiosError = error as AxiosError;

        if (axiosError.response?.status == 401) {
            window.location.replace("/login/authentication/error");
        }

        return { contracts: [] as Contract[], hasError: true };
    }
}