
export const BreadCrumbComponent = () => {
    return (<div className="row">
        <nav className="bread-crumb">
            <ol className="flex space-x-3">
                <li className="flex items-center">
                    <a className="flex items-center hover:underline" href="https://kundportal.mkbfastighet.se/">
                        <svg viewBox="0 0 23.9 23.1" className="bread-crumb-icon ms-0" data-cid="Icon"><path d="M.8 12.3c-.2 0-.4-.1-.6-.2-.3-.3-.3-.8 0-1.1L11.6.2c.3-.3.8-.3 1.1 0l11 10.7c.3.3.2.9-.1 1.1-.3.3-.7.3-1 0L12.1 1.9 1.4 12.1c-.2.1-.4.2-.6.2z"></path><path d="M19.9 23.1h-5.5c-.4 0-.8-.4-.8-.8v-5.1h-2.7v5.1c0 .4-.4.8-.8.8H4.3c-.4 0-.8-.4-.8-.8V11.8c0-.4.3-.8.7-.9s.8.3.9.7V21.5h4.2v-5.2c0-.4.4-.8.8-.8h4.3c.4 0 .8.4.8.8v5.2h3.9v-9.7c0-.4.4-.8.8-.8s.8.4.8.8v10.5c0 .5-.3.8-.8.8z"></path></svg>
                        Mina sidor
                    </a>
                </li>
                <li className="flex items-center">
                    <svg viewBox="0 0 24 24" className="bread-crumb-icon" data-cid="Icon"><path d="M9 24c-.3 0-.6-.1-.8-.3-.5-.4-.5-1.2 0-1.6L17.8 12 8.2 1.9c-.4-.5-.4-1.2 0-1.6.5-.4 1.2-.4 1.6 0l10.4 10.8c.4.4.4 1.1 0 1.6L9.9 23.6c-.3.3-.6.4-.9.4z"></path></svg>
                </li>
                <li className="flex items-center">
                    Serviceanmälan
                </li>
            </ol>
        </nav>
    </div>);
}