import React from 'react';
import logo from './logo.svg';

import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

import { AuthProvider } from './providers/authProvider';
import { BrowserRouter } from 'react-router-dom';
import { setupRoutes } from './routes/setupRoutes';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faCircleMinus, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { UserManager } from 'oidc-client-ts';
import AuthService from './services/authService';

library.add(faCheckSquare, faCoffee, faCircleMinus, faCircleXmark)

function App() {
    return (
        <AuthProvider>
            <BrowserRouter children={setupRoutes} basename={"/"} />
        </AuthProvider>
  );
}

export default App;
