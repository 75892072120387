import React from "react";
import { Link } from "react-router-dom";
import { Customer } from "../../models/CustomerModels";
import { AuthConsumer, useAuthentication } from "../../providers/authProvider";
import { BreadCrumbComponent } from "../BreadCrumbComponent";
import { HeaderComponent } from "../HeaderComponent";
import { PrivateComponent } from "../privateComponent";

export const AuthenticationError = () => {
    return (
        <div>
            <HeaderComponent customer={{} as Customer} />
            <div className="body-container">
                <div className="body-container-margin">
                    <div className="body-container-inner">
                        <BreadCrumbComponent />
                        <div className="pb-5 pt-5 h5">
                            Något har gått fel, klicka <Link to="/logout"><button type="button" className="btn btn-sm btn-secondary ms-2">Logga ut</button></Link> eller gå tillbaka till <a href="https://www.mkbfastighet.se/">https://www.mkbfastighet.se/</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};