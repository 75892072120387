import { AxiosError } from "axios";
import HttpClient from "../clients/HttpClient";
import { Customer } from "../models/CustomerModels";
import AuthService from "./authService";

export async function GetCustomer(): Promise<{ customer: Customer, hasError: boolean}> {
    try {

        var session = AuthService.getSession()

        if (session?.access_token)
        {
            var client = HttpClient(session?.access_token);

            var customer = await client.get(`/customer`).then((response) => {
                if (response.data) {
                    return { customer: response.data, hasError: false };
                }
                else {
                    return { customer: {} as Customer, hasError: false };
                }
            })

            return customer;
        }

        return { customer: {} as Customer, hasError: true };
    }
    catch (error) {

        var axiosError = error as AxiosError;

        if (axiosError.response?.status == 401) {
            window.location.replace("/login/authentication/error");
        }
        
        return { customer: {} as Customer, hasError: true };
    }
}

