import Moment from 'react-moment';
import { ServiceReport } from '../models/ServiceReportModels';

const CustomerServiceReportsComponent = ({ customerServiceReports }: {customerServiceReports : ServiceReport[]}) => {
    return (
        <div className="ms-3 mb-5">
            <div className="row d-none d-md-flex">
                <div className="col-md-3 service-report-table-md-labels">Registrerat</div>
                <div className="col-md-2 service-report-table-md-labels">Ärendenummer</div>
                <div className="col-md-2 service-report-table-md-labels">Status</div>
                <div className="col-md-5 service-report-table-md-labels">Ärendebeskrivning</div>
            </div>
            {customerServiceReports?.map(customerServiceReport => <CustomerServiceReportComponent customerServiceReport={customerServiceReport} key={customerServiceReport.id}></CustomerServiceReportComponent>)}
        </div>
    );
}

const CustomerServiceReportComponent = ({ customerServiceReport }: { customerServiceReport: ServiceReport }) => {

    return (
        <div className="border-top">
            <div className="row mb-2 d-none d-md-flex">
                <div className="col-md-3"> <Moment date={customerServiceReport.createDate} format='YYYY-MM-DD HH:mm' /></div>
                <div className="col-md-2">{customerServiceReport.number}</div>
                <div className="col-md-2">{customerServiceReport.status}</div>
                <div className="col-md-5">{customerServiceReport.specification}</div>
            </div>
            <div className="row d-sm-flex d-md-none">
                <div className="col-sm-5 service-report-table-sm-labels">Registrerat</div>
                <div className="col-sm-7"> <Moment date={customerServiceReport.createDate} format='YYYY-MM-DD HH:mm' /></div>
            </div>
            <div className="row d-sm-flex d-md-none">
                <div className="col-sm-5 service-report-table-sm-labels">Ärendenummer</div>
                <div className="col-sm-7">{customerServiceReport.number}</div>
            </div>
            <div className="row d-sm-flex d-md-none">
                <div className="col-sm-5 service-report-table-sm-labels">Status</div>
                <div className="col-sm-7">{customerServiceReport.status}</div>
            </div>
            <div className="row d-sm-flex d-md-none">
                <div className="col-sm-12 service-report-table-sm-labels">Ärendebeskrivning</div>
            </div>
            <div className="row d-sm-flex d-md-none mb-5">
                <div className="col-sm-12">{customerServiceReport.specification}</div>
            </div>
        </div>
    );

}

export default CustomerServiceReportsComponent;