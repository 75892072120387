import React, { useEffect, useState } from 'react';
import { Customer } from '../models/CustomerModels';
import { ServiceReport, ServiceReportObject } from '../models/ServiceReportModels';
import { GetServiceReportObjects } from '../services/ServiceReportService';
import CustomerServiceReportsComponent from './CustomerServiceReportsComponent';

interface DisplaySettings {
    showLoadingSpinner: boolean
    showErrorInformation: boolean
}

const CustomerServiceReportObjectsComponent = ({ customer, lastCreated }: { customer: Customer, lastCreated: ServiceReportObject }) => {
    const [serviceReportObjects, setServiceReportObjects] = useState<ServiceReportObject[]>([] as ServiceReportObject[]);
    const [displaySettings, setDisplaySettings] = useState<DisplaySettings>({} as DisplaySettings);

    useEffect(() => {
        if ([...serviceReportObjects].find(e => e.id === lastCreated.id)) {
            var newArray = [...serviceReportObjects].map(obj => {
                if (obj.id === lastCreated.id) {
                    obj.serviceReports = [lastCreated.serviceReports[0]].concat(obj.serviceReports);
                }

                return obj;
            });
            setServiceReportObjects(newArray as ServiceReportObject[]);
        }
        else {
            var newArray = [lastCreated].concat([...serviceReportObjects]);

            setServiceReportObjects(newArray as ServiceReportObject[]);
        }
    }, [lastCreated]);

    useEffect(() => {
        getServiceReportObjects();
    }, [])

    const getServiceReportObjects = () => {
        setDisplaySettings((prevState) => ({ ...prevState, showLoadingSpinner: true, showErrorInformation: false }));
        setServiceReportObjects([] as ServiceReportObject[]);

        GetServiceReportObjects().then((response) => {
            
            setServiceReportObjects(response.serviceReportObjects)

            if (response.hasError) {
                setDisplaySettings((prevState) => ({ ...prevState, showErrorInformation: true }));
            }
        }).finally(() => {
            setDisplaySettings((prevState) => ({ ...prevState, showLoadingSpinner: false }));
        });
    }

    const ShowGetServiceReports = () => {
        return (
            <div>
                <div className="spinner-border spinner-border-small me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                Hämtar mina ärenden
            </div>
        )
    }

    return (
        <div>
            <div className="component-header text-border">Mina ärenden</div>
            {displaySettings.showLoadingSpinner ? <ShowGetServiceReports /> : serviceReportObjects?.map(serviceReportObject => <CustomerServiceReportObjectComponent customerServiceReportObject={serviceReportObject} key={serviceReportObject.id + serviceReportObject.description}></CustomerServiceReportObjectComponent>)}
            <div></div>
        </div>
    );
}


const CustomerServiceReportObjectComponent = ({ customerServiceReportObject }: { customerServiceReportObject: ServiceReportObject }) => {
    return (
        <div>
            <div className="service-report-objectName">{FormatDescription(customerServiceReportObject.description)}</div>
            <CustomerServiceReportsComponent customerServiceReports={customerServiceReportObject.serviceReports}></CustomerServiceReportsComponent>
        </div>
    );
}

const FormatDescription = (description: string) => {
    var regexp = new RegExp("^[0-9]{3}-[0-9]{3}-[0-9]{2}");
    if (regexp.test(description)) {
        return (description.substring(description.indexOf(' ') + 1) + ' [' + description.substring(0, description.indexOf(' ')) + ']');
    }

    return description
}

export default CustomerServiceReportObjectsComponent;