import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Customer } from "../models/CustomerModels";
import { AuthConsumer, useAuthentication } from "../providers/authProvider"
import { GetCustomer } from "../services/CustomerService";
import { BreadCrumbComponent } from "./BreadCrumbComponent";
import { HeaderComponent } from "./HeaderComponent";
import ServiceReportViewComponent from "./ServiceReportViewComponent";

export const PrivateComponent = () => {
    var authentication = useAuthentication();

    const [customer, setCustomer] = useState<Customer>({} as Customer);

    useEffect(() => {
        if (authentication.isAuthenticated()) {
            GetCustomer().then((response) => {
                setCustomer(response.customer);
            });
        }
    }, [authentication]);

    return (
        <AuthConsumer>
            {((isAuthenticated) => {
                if (isAuthenticated) {
                    return (
                        <div>
                            <HeaderComponent customer={customer} />
                            <div className="body-container">
                                <div className="body-container-margin">
                                    <div className="body-container-inner">
                                        <BreadCrumbComponent />
                                        <div className="row pb-5">
                                            <ServiceReportViewComponent customer={customer}></ServiceReportViewComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                }
            })}
        </AuthConsumer>
        )
}