import axios, { Axios } from 'axios';

export const HttpClient = (access_token:string) => {

    const defaultOptions = {
        baseURL: process.env.REACT_APP_BACKEND_API,
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
        },
    };

    var instance = axios.create(defaultOptions);

    instance.interceptors.request.use(function (config) {
        config.headers!.Authorization = `Bearer ${access_token}`;
        return config;
    });

    return instance;
}

export default HttpClient;