import { User } from "oidc-client-ts";
import React, { Component, ReactNode, useContext, useEffect, useState } from "react";
import { Session } from "../models/AuthenticationModels";
import AuthService from "../services/authService";

interface IAuthContext {
    //getUser: () => Promise<User | null>,
    getSession: () => Session | undefined,
    signinRedirectCallback: () => void,
    logout: () => void,
    signoutRedirectCallback: () => void,
    isAuthenticated: () => boolean,
    signinRedirect: () => void,
    signinSilentCallback: () => void
}

const AuthContext = React.createContext<IAuthContext>({
    //getUser: () => new Promise<User | null>(() => {}),
    getSession: () => ({} as Session),
    signinRedirectCallback: () => { },
    logout: () => { },
    signoutRedirectCallback: () => { },
    isAuthenticated: () => false,
    signinRedirect: () => { },
    signinSilentCallback: () => { }
});

export const AuthConsumer = AuthContext.Consumer;

export const useAuthentication = () => {
    return useContext(AuthContext);
}

type AuthProviderProperties = {
    children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProperties) => {
    console.log("AuthProvider Created");
    const authService = new AuthService();

    const [user, setUser] = useState<User | null>(null);

    const signinRedirectCallback = async () => {
        await authService.signinRedirectCallback();
    }

    //const getUser = async () => {
    //    console.log("existing user is: ", user);

    //    if (!user) {
    //        await authService.getUser().then(user => {
    //            setUser(user);
    //            return user;
    //        })
    //    }

    //    return user;
    //}

    const logout = async () => {
        await authService.logout();
    }

    const signoutRedirectCallback = async () => {
        await authService.signoutRedirectCallback();
    }

    const getSession = () => {
        return AuthService.getSession();
    }

    const isAuthenticated = () => {
        return AuthService.isAuthenticated();
    }

    const signinRedirect = async () => {
        await authService.signinRedirect();
    }

    const signinSilentCallback = async () => {
        await authService.signinSilentCallback();
    }

    const value = {
        //getUser,
        getSession,
        signinRedirectCallback,
        logout,
        signoutRedirectCallback,
        isAuthenticated,
        signinRedirect,
        signinSilentCallback,
    };

    /**
     * Log out user if they are not a MKB customer
     */
    useEffect(() => {
        authService.isEmailCustomer()
            .then(isEmailCustomer => {
                if (isEmailCustomer) {
                    logout();
                }
            })
    }, [])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
