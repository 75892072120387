import { Customer } from "../../models/CustomerModels";
import { AuthConsumer } from "../../providers/authProvider";
import { BreadCrumbComponent } from "../BreadCrumbComponent";
import { HeaderComponent } from "../HeaderComponent";

export const LoginCallback = () => {
    return (<AuthConsumer>
        {({ signinRedirectCallback }) => {
            signinRedirectCallback();

            return (<div>
                <HeaderComponent customer={{} as Customer} />
                <div className="body-container">
                    <div className="body-container-margin">
                        <div className="body-container-inner">
                            <BreadCrumbComponent />
                            <div className="pb-5 pt-5 h5">
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                <span>loading</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }}
    </AuthConsumer>)
}