import React, { useEffect, useState } from 'react';
import { Customer } from '../models/CustomerModels';
import { ServiceReportObject } from '../models/ServiceReportModels';
import { useAuthentication } from '../providers/authProvider';
import CustomerCreateServiceReportComponent from './CustomerCreateServiceReportComponent';
import CustomerServiceReportObjectsComponent from './CustomerServiceReportObjectsComponent';

export const ServiceReportViewComponent = ({ customer }: { customer: Customer } ) => {
    var authentication = useAuthentication();

    const [created, setCreated] = useState<ServiceReportObject>({} as ServiceReportObject);

    useEffect(() => {
        if (window.location.hash) {
            const element = document.querySelector(window.location.hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [])

    return (
        <div>
            <div className="pb-5 service-report-create-component">
                <CustomerCreateServiceReportComponent customer={customer} onCreate={setCreated}></CustomerCreateServiceReportComponent>
            </div>
            <div className={`pb-5 ${window.location.hash ? 'service-report-list-component' : ''}`} id="serviceReports">
                <CustomerServiceReportObjectsComponent customer={customer} lastCreated={created}></CustomerServiceReportObjectsComponent>
            </div>
        </div>
    );
}

export default ServiceReportViewComponent;