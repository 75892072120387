import React from "react";
import { Customer } from "../../models/CustomerModels";
import { AuthConsumer, useAuthentication } from "../../providers/authProvider";
import { BreadCrumbComponent } from "../BreadCrumbComponent";
import { HeaderComponent } from "../HeaderComponent";
import { PrivateComponent } from "../privateComponent";

export const Login = () =>  {
    return (
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {
                if (isAuthenticated()) {
                    return <PrivateComponent />;
                } else {
                    signinRedirect();
                    return (<div>
                        <HeaderComponent customer={{} as Customer} />
                        <div className="body-container">
                            <div className="body-container-margin">
                                <div className="body-container-inner">
                                    <BreadCrumbComponent />
                                    <div className="pb-5 pt-5 h5">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        <span>loading</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>);
                }
            }}
        </AuthConsumer>
    );
};