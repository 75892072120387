import { Routes, Route } from "react-router-dom";

import { Login } from "../components/auth/login";
import { Logout } from "../components/auth/logout";
import { LogoutCallback } from "../components/auth/logoutCallback";
import { LoginSilentCallback } from "../components/auth/loginSilentCallback";
import { LoginCallback } from "../components/auth/loginCallback";
import { AuthenticationError } from "../components/auth/authenticationError";

export const setupRoutes = (
    <Routes>
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/logout/callback" element={<LogoutCallback />} />
        <Route path="/login/silent/callback" element={<LoginSilentCallback />} />
        <Route path="/serviceanmalan" element={<Login />} />
        <Route path="/serviceanmalan/" element={<Login />} />
        <Route path="/serviceanmalan/ny" element={<Login />} />
        <Route path="/login/authentication/error" element={<AuthenticationError />} />
        <Route path="/" element={<Login />} />
    </Routes>
);