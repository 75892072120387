import { Link } from "react-router-dom";
import { Customer } from "../models/CustomerModels";

export const HeaderComponent = (headerProperties: { customer: Customer }) => {
  return (<div className="header-container">
    <div className="header-container-margin">
      <div className="header-container-inner">
        <div className="float-start">
          <a href="http://www.mkbfastighet.se"><img src="https://www.mkbfastighet.se/gui/images/mkb-logo.svg" alt="Till MKB startsida" className="header-image" /></a>
        </div>

        <div className="float-end text-end">
          <span className="header-customer">{headerProperties.customer.firstName} {headerProperties.customer.lastName}  [Kundnummer: {headerProperties.customer.customerNr}]</span><br />
          <Link to="/logout"><button type="button" className="btn btn-sm btn-secondary ms-2 mt-2">Logga ut</button></Link>
        </div>

      </div>
    </div>
  </div>);
}