import { IDENTITY_CONFIG, METADATA_OIDC } from "../utils/authConst";
import { UserManager, WebStorageStateStore, Log, UserManagerSettings } from "oidc-client-ts";
import { Session } from "../models/AuthenticationModels";

export default class AuthService {
    private UserManager: UserManager | null = null;

    constructor() {
        if (!this.UserManager) {
            this.UserManager = new UserManager({
                ...IDENTITY_CONFIG,
                userStore: new WebStorageStateStore({ store: window.sessionStorage }),
                metadata: {
                    ...METADATA_OIDC
                }
            } as UserManagerSettings);

            // Logger
            Log.setLogger(console);
            Log.setLevel(parseInt(process.env.REACT_APP_AUTH_LOG ?? '0'));

            this.UserManager.events.addUserLoaded((user) => {
                if (window.location.href.indexOf("signin-oidc") !== -1) {
                    this.navigateToScreen();
                }
            });
            this.UserManager.events.addSilentRenewError((e) => {
                AuthService.clearSession();
            });

            this.UserManager.events.addAccessTokenExpired(() => {
                AuthService.clearSession();
            });
        }
    }

    signinRedirectCallback = async () => {
        await this.UserManager?.signinRedirectCallback().then(() => {
            var hashtag = sessionStorage.getItem("redirectUriHashtag") ? sessionStorage.getItem("redirectUriHashtag") : '';

            window.location.replace(process.env.REACT_APP_PUBLIC_URL + '/' + hashtag);
            sessionStorage.removeItem("redirectUriHashtag");
        });
    };

    getUser = async () => {
        //console.log("authService.getUser");

        ////const user = JSON.parse(sessionStorage.getItem("oidc.user"));
        //const user = await this.UserManager.getUser();

        //console.log("authService.getUser.user", user);

        //if (!user) {
        //    return await this.UserManager.signinRedirectCallback();
        //}
        //return user;
    };

    /**
     * A user could potentially have an invalid token if they are authenticated as non-MKB customers. Checks if the user is not a MKB-customer.
     */
    isEmailCustomer = async () => {
        if (this.UserManager) {
            const user = await this.UserManager.getUser();
            if (user) {
                if (!("customer_number" in user.profile)) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    }

    parseJwt = (token: string) => {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        return JSON.parse(window.atob(base64));
    };


    signinRedirect = async () => {
        var hashIndex = window.location.href.indexOf('#');

        if (hashIndex > 0) {
            sessionStorage.setItem("redirectUriHashtag", window.location.href.substring(hashIndex));
        }

        localStorage.setItem("redirectUri", window.location.pathname);
        await this.UserManager?.signinRedirect({});
    };


    navigateToScreen = () => {
        window.location.replace("/en/dashboard");
    };

    static getSession = () => {
        var sessionString = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`);

        if (sessionString) {
            return JSON.parse(sessionString) as Session
        }
    }

    static isAuthenticated = () => {
        var session = AuthService.getSession();

        return !!session && !!session.access_token;
    };

    static clearSession = () => {
        //window.location.replace("/login/authentication/error");
    }

    signinSilent = async () => {
        await this.UserManager?.signinSilent()
            .then((user) => {
            })
            .catch((err) => {
                if (err.message == "login_required") {
                    this.UserManager?.clearStaleState();
                    localStorage.clear();

                    window.location.replace("/");
                }
            });
    };

    signinSilentCallback = async () => {
        await this.UserManager?.signinSilentCallback().then((user) => {
        });
    };

    logout = async () => {
        await this.UserManager?.signoutRedirect({});
        await this.UserManager?.clearStaleState();
    };

    signoutRedirectCallback = async () => {
        await this.UserManager?.signoutRedirectCallback().then(() => {
            localStorage.clear();

            if (process.env.REACT_APP_PUBLIC_URL) {
                window.location.replace(process.env.REACT_APP_PUBLIC_URL);
            }
        });
        this.UserManager?.clearStaleState();
    };
}
